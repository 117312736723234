// Your variable overrides
$body-bg: #f8f8f8;

$primary: #a21b0f;
$success: #5cb85c;
$info: #5bc0de;
$warning: #f0ad4e;
$danger: #d9534f;

$border-radius-base:        0px;
$border-radius-large:       0px;
$border-radius-small:       0px;

// Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";