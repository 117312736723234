.knownissues {

    .knownissue-card {
        margin-bottom: 24px;

        .info-text {
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: 0.5rem;
        }

        h6 {
            margin-bottom: 4px;
            font-size: 14px;
        }
    }

    .confluence-embedded-file-wrapper {
        display: block;
        margin: 10px 0;

        &.image-center-wrapper {
            text-align: center;
        }
    } 

    .alert {
        p:last-child {
            margin-bottom: 0;
        }
    }
}