html,
body {
  font-size: 14px;
}

body {
  padding-bottom: 20px;
}

p {
  margin-bottom: 1.3rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 767px) {  
  .site-title {
    font-weight: 600;
  }
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

code {
  color: #e01a76;
}

.navbar .nav-item {
  border-left: 1px solid #eee;

  &:first-child {
    border-left: 0 none;
  }
}

.navbar {
  padding: 1rem;
  background-color: white;
  font-size: 1rem;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;

  .full-logo {
    max-height: 42px;
  }

  .square-logo {
      display: none;
  }

  .site-title {
      vertical-align: bottom;
      margin-left: 1rem;
      font-size: 1.5rem;
  }

  @media (max-width: 767px) {

    .full-logo {
        display: none;
    }

    .square-logo {
        display: inline;
        max-height: 32px;
    }
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.05);
}

.loading-fade {
  position: relative;
  opacity: 0.33;

  > ::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;

    cursor: not-allowed;
  }
}
.pagination {
  border-radius: 0;

  .page-item .page-link {
    border-radius: 0 !important;
    color: #a21b0f;
  }

  .page-item.active .page-link {
    background-color: #a21b0f;
    border-color: #a21b0f;
    color: white;
  }
}

.jumbotron {
  background: url('/jumbotron-bg.jpg');
  background-size: cover;
  color: #eee;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.05);

  hr {
    border-color: rgba(255,255,255,0.3);
  }

  a {
    color: #eeeeee;
  }
}

.home-card-link, .home-card-link:hover, .home-card-link:visited, .home-card-link:focus {
  color: inherit;
  text-decoration: inherit;
}

.home-card-link:hover {
  .card {
    background-color: #eee;
  }
}

.home-card-image {
  text-align: center;
  padding: 2rem 0;

  img.card-img-top {
    width: 50%;
  }
}