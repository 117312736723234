.bulletin {
    font-size: 16px;

    img {
        max-width: 100%;
        box-shadow: 1px 1px 8px 0px rgba(0,0,0,0.2);
    }

    .bulletin-card {
        margin-bottom: 24px;
    }

    .confluence-embedded-file-wrapper {
        display: block;
        margin: 1.5rem 0;

        &.image-center-wrapper {
            text-align: center;
        }
    } 

    .alert {
        p:last-child {
            margin-bottom: 0;
        }
    }
}